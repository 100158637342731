import React, { type ReactElement, type ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { type NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';

import { CssBaseline } from '@mui/material';

import '../styles/global.css';
import '@styles/nprogress.css';
import '@styles/multipleDatePicker.css';
import { AuthContextProvider } from '@client/context/auth/AuthContext';
import SnackbarContextProvider from '@client/context/SnackbarContext';
import getIntercomClientEnvironmentId from '@config/getIntercomClientEnvironmentId';
import { ThemeProvider } from '@emotion/react';
import { theme } from '@styles/theme';
import { Analytics } from '@vercel/analytics/next';
import NProgress from 'nprogress'; // nprogress module

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Binding events. => this should display a loading bar
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

const INTERCOM_APP_ID = getIntercomClientEnvironmentId();

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1 user-scalable=no' />
      </Head>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <SnackbarContextProvider>
              <CssBaseline />
              {getLayout(<Component {...pageProps} />)}
              <Analytics />
            </SnackbarContextProvider>
          </ThemeProvider>
        </AuthContextProvider>
      </IntercomProvider>
    </>
  );
};

export default MyApp;
